import React, { useState, useContext } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { ThemeContext } from '../contexts/ThemeContext';
import {updateUserSettings, logoutUser} from '../utils/api'
import { ROLES } from '../constants/roleConstants';
import { Link, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box, IconButton, Typography, Divider, List,
  ListItemButton, ListItemIcon, ListItemText, CssBaseline, Drawer, useMediaQuery,
  TextField, InputAdornment, Avatar, Menu, MenuItem, Switch
} from '@mui/material';
import {
  Menu as MenuIcon, Dashboard as DashboardIcon, Add as AddIcon,
  AccountBalance as AccountBalanceIcon, ListAlt as ListAltIcon,
  AccountCircle as AccountCircleIcon, Search as SearchIcon,
  Person as PersonIcon, Logout as LogoutIcon, Brightness4 as Brightness4Icon
} from '@mui/icons-material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

const drawerWidth = 240; // width for expanded drawer
const miniDrawerWidth = 64; // width for mini drawer

// Mixins for drawer opened/closed states
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: 'width 0.2s ease',
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  width: miniDrawerWidth,
  transition: 'width 0.2s ease',
  overflowX: 'hidden',
});

const DrawerStyled = styled(Drawer)(({ theme, open }) => ({
  width: open ? drawerWidth : miniDrawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(
    open
      ? { ...openedMixin(theme),
          '& .MuiDrawer-paper': {
            ...openedMixin(theme),
            backgroundColor: 'var(--color-background)',
            color: 'var(--color-text)',
          }
        }
      : { ...closedMixin(theme),
          '& .MuiDrawer-paper': {
            ...closedMixin(theme),
            backgroundColor: 'var(--color-background)',
            color: 'var(--color-text)',
          }
        }
  )
}));

const DrawerHeader = styled('div')(({ theme, open }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(1),
  justifyContent: 'space-between',
  width: open ? drawerWidth : miniDrawerWidth,
}));

// Role-based menu
const menuItems = {
  [ROLES.ADMIN]: [
    { label: 'Dashboard', path: '/vanta-tracker/dashboard', icon: <DashboardIcon /> },
    { label: 'Add Users', path: '/vanta-tracker/add-users', icon: <AddIcon /> },
    { label: 'Accounts', path: '/vanta-tracker/accounts', icon: <AccountBalanceIcon /> },
    { label: 'Transactions', path: '/vanta-tracker/transactions', icon: <ListAltIcon /> },
  ],
  [ROLES.SUPRAUSER]: [
    { label: 'Dashboard', path: '/vanta-tracker/dashboard', icon: <DashboardIcon /> },
    { label: 'Accounts', path: '/vanta-tracker/accounts', icon: <AccountBalanceIcon /> },
  ],
  [ROLES.USER]: [
    { label: 'Dashboard', path: '/vanta-tracker/dashboard', icon: <DashboardIcon /> },
  ]
};

function UserMenu() {
  const { theme, setTheme, toggleTheme } = useContext(ThemeContext);
  const {setUser ,accessToken} = useContext(AuthContext);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => setAnchorEl(null);

  const handleToggleTheme = async (event) => {
    const newTheme = event.target.checked ? 'dark' : 'light';
    setTheme(newTheme);
    // handleClose();   // only if you want to close after toggle

    try {
      await updateUserSettings(accessToken, { themePreference: newTheme });
    } catch (error) {
      console.error('Failed to update theme preference:', error);
      // Optionally revert the theme if update fails
      // setTheme(theme === 'dark' ? 'light' : 'dark');
    }
  };

  const handleProfile = () => {
    // Navigate to profile or handle profile logic here
    handleClose();
  };

  const handleLogout = async () => {
    try {
      await logoutUser(accessToken);
      setUser(null);
      localStorage.removeItem('role');
      navigate('/vanta-tracker/login');

    } catch (error) {
      console.error('Failed to logout:', error);
    } finally {
      handleClose();
    }
  };

  return (
    <React.Fragment>
      <IconButton onClick={handleMenuClick} sx={{ color: 'var(--color-text)' }}>
        <Avatar sx={{ bgcolor: 'var(--color-background)' }}>
          <AccountCircleIcon sx={{ color: 'var(--color-icon)' }}/>
        </Avatar>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        PaperProps={{
          style: {
            minWidth: '200px',
            backgroundColor: 'var(--color-background)',
            color: 'var(--color-text)',
            transition: 'background-color 0.2s ease, color 0.2s ease'
          }
        }}
      >
        <MenuItem onClick={handleProfile}>
          <ListItemIcon sx={{color: 'var(--color-text)'}}>
            <PersonIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Profile</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon sx={{color: 'var(--color-text)'}}>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Logout</ListItemText>
        </MenuItem>
        <MenuItem>
          <ListItemIcon sx={{color: 'var(--color-text)'}}>
            <Brightness4Icon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Dark Mode</ListItemText>
          <Switch
            checked={theme === 'dark'} // Assuming you can access current theme from context or props
            onChange={handleToggleTheme}
            sx={{
              '& .MuiSwitch-track': {
                backgroundColor:'var(--color-border)',
              },
              '& .MuiSwitch-thumb': {
                backgroundColor:'var(--color-icon)'
              }
            }}
          />
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

function VantaTrackerLayout({ children }) {
  const isDesktop = useMediaQuery('(min-width:600px)');
  const [open, setOpen] = useState(true);
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useContext(AuthContext);
  const userRole = user?.role || localStorage.getItem('role');
  const currentMenu = menuItems[userRole] || [];

  const handleDesktopToggle = () => {
    setOpen(!open);
  };

  const handleMobileToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <DrawerStyled
      variant={isDesktop ? 'permanent' : 'temporary'}
      open={isDesktop ? open : mobileOpen}
      onClose={() => setMobileOpen(false)}
    >
      <DrawerHeader open={isDesktop ? open : mobileOpen}>
        {open ? (
          <Typography variant="h6" noWrap component="div">
            Vanta Tracker
          </Typography>
        ) : (
          <Typography variant="h6" noWrap component="div" sx={{ visibility: 'hidden' }}>
            .
          </Typography>
        )}
        {isDesktop && (
          <IconButton onClick={handleDesktopToggle} sx={{color:'var(--color-text)'}}>
            {open ? <ChevronLeftIcon /> : <MenuIcon />}
          </IconButton>
        )}
      </DrawerHeader>
      <Divider sx={{ borderColor:'var(--color-text)'}}/>
      <List>
        {currentMenu.map((item, index) => (
          <ListItemButton
            key={index}
            component={Link}
            to={item.path}
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5,
              color:'var(--color-text)',
              '&:hover': {
                backgroundColor: 'var(--color-hover)'
              }
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center',
                color:'var(--color-text)'
              }}
            >
              {item.icon}
            </ListItemIcon>
            {open && <ListItemText primary={item.label} />}
          </ListItemButton>
        ))}
      </List>
    </DrawerStyled>
  );

  return (
    <Box sx={{ display: 'flex', backgroundColor:'var(--color-background)', color:'var(--color-text)'}}>
      <CssBaseline />

      {!isDesktop && (
        <Box
          component="header"
          sx={{
            position: 'fixed',
            width: '100%',
            zIndex: 1300,
            display: 'flex',
            alignItems: 'center',
            backgroundColor: 'var(--color-background)',
            color: 'var(--color-text)',
            p: 1,
            transition: 'background-color 0.2s ease, color 0.2s ease'
          }}
        >
          <IconButton
            aria-label="open drawer"
            edge="start"
            onClick={handleMobileToggle}
            sx={{ mr: 1, color: 'var(--color-text)' }}
          >
            <MenuIcon />
          </IconButton>
          <TextField
            variant="outlined"
            placeholder="Search..."
            size="small"
            sx={{
              backgroundColor: 'var(--color-background)',
              color: 'var(--color-text)',
              borderRadius: 1,
              flexGrow: 1,
              mr: 1,
              '& .MuiOutlinedInput-root':{
                color:'var(--color-text)',
                '& fieldset':{
                  borderColor:'var(--color-text)'
                }
              }
            }}
            InputProps={{
              style: { color: 'var(--color-text)' },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon sx={{ color: 'var(--color-icon)' }}/>
                </InputAdornment>
              )
            }}
          />
          <UserMenu />
        </Box>
      )}

      {drawer}

      <Box component="main" sx={{ flexGrow: 1 }}>
        {isDesktop && (
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            p: 2,
            pl: open ? 2 : 1,
            justifyContent: 'space-between',
            backgroundColor: 'var(--color-background)',
            color: 'var(--color-text)',
            transition: 'background-color 0.2s ease, color 0.2s ease'
          }}>
            <TextField
              variant="outlined"
              placeholder="Search transactions, accounts, categories, bills..."
              size="small"
              sx={{
                backgroundColor: 'var(--color-background)',
                borderRadius: 1,
                width: '50%',
                mr: 1,
                '& .MuiOutlinedInput-root': {
                  color: 'var(--color-text)',
                  '& fieldset': {
                    borderColor:'var(--color-text)'
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: 'var(--color-text)' }}/>
                  </InputAdornment>
                )
              }}
            />
            <UserMenu />
          </Box>
        )}

        <Box sx={{ p: 2, backgroundColor:'var(--color-background)', color:'var(--color-text)' }}>
          {children}
        </Box>
      </Box>
    </Box>
  );
}

export default VantaTrackerLayout;
