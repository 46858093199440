import React from 'react';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const PrivateRoute = ({
    children,
    allowedRoles = [],
  }) => {
  const {isAuthenticated, user, loading} = React.useContext(AuthContext);

  if (loading) {
    return <div>Loading...</div>; // Optionally, TODO: a loader while validating the token.
  }
  console.log('PrivateRoute allowedRoles:', allowedRoles);
  const userRole = user?.role || localStorage.getItem('role')  // Get the user role from the user object or local storage. TODO: encrypt the role and store it in local storage
  console.log('PrivateRoute userRole:', userRole);
  if(!isAuthenticated){
    console.log('User not authenticated');
    return <Navigate to="/vanta-tracker/login" />;
  }

  if((allowedRoles.length > 0 && !allowedRoles.includes(userRole))){ // Check if the user is authenticated and the role is allowed
    console.log('User not allowed');
    return <Navigate to="/vanta-tracker/dashboard" />;
  }

  return children;


};

export default PrivateRoute;
