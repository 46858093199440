import React from "react";
import '../../styles/Home.css';

function Home() {
  const role = localStorage.getItem('role');  // Get role from localStorage
  const isAuthenticated = !!localStorage.getItem('token');

  return (
    <body>
      <div className="page-wrapper">
        <div className="main-wrapper">
          <div className="section_hero">
            <div className="tabs">
              <div className="tabs_tab">
                <div className="tabs-tab-content">
                  <div className="tabs_tab-content-left">
                    <p className="heading-style-h3">
                      [01]
                    </p>
                  </div>
                  <div className="tabs_tab-content-right">
                    <img src="/images/Hero-image-face-1.png" alt="Hero men face" loading="lazy" sizes="100vw">
                    </img>
                  </div>
                </div>
                <div className="tabs-tab-heading">
                  <h1 className="heading-style-h1">
                    About me
                  </h1>

                </div>
              </div>
              <div className="tabs_tab is-2 closed">

              </div>
              <div className="tabs_tab is-3 closed">

              </div>

            </div>

          </div>

        </div>
      </div>
    </body>
  );
}

export {Home};
