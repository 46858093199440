import React from "react";
import "../../styles/Projects.css"

function Projects (){
  const role = localStorage.getItem('role');


  return (
    <>
      <div className="page-wrapper">
        <div className="section_description-work">
          <div className="padding-global padding-section-huge">
            <div className="container-works">
              <div className="container_flext-hrzntl align-end mob">
                <h1 className="heading-style-h2">
                  Selected
                  <span className="text-weight-medium"> works</span>
                </h1>
                <p>[PROJECTS]</p>
              </div>
              <div className="slider_wrapper">
                <div className="slider_collection-list-wrapper swiper w-dyn-list swiper-initialized swiper-horizontal swiper-backface-hidden">
                  <div role="list" className="slider_collection-list swiper-wrapper w-dyn-items">
                    <div className="menu_btn" >
                      <a href="/vanta-tracker/login" className="">
                        <p className="caption text-color-alternate text-weight-medium">
                          [vanta tracker]
                        </p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div className="footer">
        <div className="padding-global padding-section-footer">
          <div className="container-footer">
            <div className="column_left">
              <div className="margin-bottom margin-2-5">
                <div className="heading-style-h3">
                  For any collaborative projects
                  <br></br>
                  or inquiries feel free to reach out to me.
                </div>
              </div>
              <div className="connect_wrapper">
                <p>
                  Currently available
                  <br></br>
                  for freelance projects
                </p>
                <a href="#" className="button service-page w-inline-block">
                  <p>Let's connect in WhatsApp</p>
                </a>
              </div>
              <div className="social_wrapper">
                <a href="#" className="footer_links">WhatsApp</a>
                <a href="#" className="footer_links">Instagram</a>
                <a href="#" className="footer_links">email</a>
              </div>

            </div>
            <div className="column_right-footer">
              <div className="heading-style-h1-projects text-align-right mob_text-align-left">
                start
                <br></br>
                a Project!
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export {Projects};
