import React, { useState, useEffect } from 'react';
import UserList from '../../components/UserList';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../contexts/AuthContext';

function AddUsers() {
  // const { users, handleAddUser } = React.useContext(AuthContext);
  const [user, setUser] = useState({name:'', email:'', role:'us3r', password:'', lastName:''}); //const [state, setState] = React.useState()
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const role = localStorage.getItem('role');  // Get role from localStorage
  console.log("Current role:", role);  // For debugging

  // useEffect(() => {
  //   // Check if the user is an admin
  //   if (!role || role !== 'adm1n') {
  //     setError(`Access denied. User role: ${role} || ' unkown' is not authorized to access this page.`);
  //     // Optionally redirect to a different page
  //     setTimeout(() => {
  //       navigate('/');
  //     }, 3000); // Redirect after 3 seconds
  //   }
  // }, [role, navigate]);

  const handleSubmit = async () => {
    console.log("handle submit:", user);  // For debugging
    // try {
    //   const response = await handleAddUser(user);

    //   if (response.ok) {
    //     setUser({ name: '', email: '', role: 'us3r', password: '', lastName: '' }); // Clear the form
    //     setError(''); // Clear errors
    //   } else {
    //     const errorData = await response.json();
    //     setError(`Error: ${errorData.message}`); // Display backend error message
    //   }
    // }catch(e){
    //   setError(`Error: ${e}`);
    // }
  };

  // if (!role || role !== 'adm1n') {
  //   return <p>{error}</p>;  // Show error message for unauthorized users
  // }

  return (
    <div>
      <h1>Add Users</h1>
      <input
        type="text"
        placeholder='Name'
        value={user.name}
        onChange={(e) => setUser({ ...user, name: e.target.value })}
      />
      <input
        type="text"
        placeholder="Last Name"
        value={user.lastName}
        onChange={(e) => setUser({ ...user, lastName: e.target.value })}
      />
      <input
        type="email"
        placeholder='Email'
        value={user.email}
        onChange={(e) => setUser({ ...user, email: e.target.value })}
      />
      <input
        type="password"
        placeholder="Password"
        value={user.password}
        onChange={(e) => setUser({ ...user, password: e.target.value })}
      />
      <select
        value={user.role}
        onChange={(e) => setUser({ ...user, role: e.target.value })}
      >
        <option value="us3r">User</option>
        <option value="supraus3r">Suprauser</option>
        <option value="adm1n">Admin</option>
      </select>
      <button onClick={handleSubmit}>Add User</button>
      {error && <p>{error}</p>}
      <h2>User List</h2>
      {/* <UserList users={users} /> Render the UserList component */}
    </div>
  );
}

export default AddUsers;
