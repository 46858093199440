import React, { useEffect, useContext, useState  } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { DataContext } from '../../contexts/DataContext';
import { Box, Grid, Paper, Typography,
  List, ListItem, ListItemText,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Collapse, IconButton
} from '@mui/material';
import { KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import "../../styles/Dashboard.css"

function Dashboard () {
  const { user } = React.useContext(AuthContext);
  const { users, ifrsClassifications, accounts,
    transactions, transactionLines, fetchData, currencies
  } = useContext(DataContext);
  const role = localStorage.getItem('role');
  const [openRow, setOpenRow] = useState({}); // Track which rows are expanded

  useEffect(() => {
    fetchData();  // Fetch data when the component mounts
  }, [fetchData]);

  const handleRowToggle = (transactionId) => {
    setOpenRow((prev) => ({
      ...prev,
      [transactionId]: !prev[transactionId],
    }));
  };

  const renderTransactionLines = (transactionId) => {
    const lines = transactionLines.filter(line => line.transactionId === transactionId);
    return (
      <Table size="small" sx={{ ml: 4 }}>
      <TableHead>
        <TableRow>
          <TableCell sx={{ color: 'var(--color-text)' }}>Account Name</TableCell>
          <TableCell sx={{ color: 'var(--color-text)' }}>Currency</TableCell>
          <TableCell sx={{ color: 'var(--color-text)' }}>Debit</TableCell>
          <TableCell sx={{ color: 'var(--color-text)' }}>Credit</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((line) => (
          <TableRow key={line.id}>
            <TableCell sx={{ color: 'var(--color-text-secondary)' }}>
              {accounts.find(account => account.id === line.accountId)?.name || 'N/A'}
            </TableCell>
            <TableCell sx={{ color: 'var(--color-text-secondary)' }}>
              {currencies.find(currency => currency.id === line.currencyId)?.code || 'N/A'}
            </TableCell>
            <TableCell sx={{ color: 'var(--color-text-secondary)' }}>
              {line.lineType === 'DEBIT' ? (Number(line.amount) || 0).toFixed(2) : ''}
            </TableCell>
            <TableCell sx={{ color: 'var(--color-text-secondary)' }}>
              {line.lineType === 'CREDIT' ? (Number(line.amount) || 0).toFixed(2) : ''}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    );
  };


  return (
    <Box className="dashboard_page">
      <Typography className="headline" variant="h4" gutterBottom>
        Welcome {user.name} {user.lastName} to the Vanta Tracker!
      </Typography>
      <Typography className="message" variant="subtitle1">
        You have successfully logged in as {role}.
      </Typography>

      <Grid container spacing={2} sx={{ mt: 2 }}>
        {/* Users */}
        <Grid item xs={12} md={4}>
          <Paper sx={{
            p: 2,
            backgroundColor:'var(--color-surface)',
            color:'var(--color-text)',
            border: '1px solid var(--color-border)',
            transition: 'background-color 0.2s ease, color 0.2s ease',
            maxHeight: 300,
            overflowY: 'auto',
          }}>
            <Typography variant="h6" className="headline">Users</Typography>
            <Typography className="message">List of users</Typography>
            <List>
              {users.map((user) => (
                <ListItem key={user.id}>
                  <ListItemText
                    primary={<span style={{ color: 'var(--color-text)' }}>{user.name}</span>}
                    secondary={<span style={{ color: 'var(--color-text-secondary)' }}>{user.email}</span>}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* IFRS Classifications */}
        <Grid item xs={12} md={4}>
          <Paper sx={{
            p: 2,
            backgroundColor:'var(--color-surface)',
            color:'var(--color-text)',
            border: '1px solid var(--color-border)',
            transition: 'background-color 0.2s ease, color 0.2s ease',
            maxHeight: 300,
            overflowY: 'auto',
          }}>
            <Typography variant="h6" className="headline">IFRS Classifications</Typography>
            <Typography className="message">List of IFRS Classifications</Typography>
            <List>
              {ifrsClassifications.map((classification) => (
                <ListItem key={classification.id}>
                  <ListItemText
                    primary={<span style={{ color: 'var(--color-text)' }}>{classification.name}</span>}
                    secondary={<span style={{ color: 'var(--color-text-secondary)' }}>{classification.description}</span>}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Accounts */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2,
            backgroundColor:'var(--color-surface)',
            color:'var(--color-text)',
            border: '1px solid var(--color-border)',
            transition: 'background-color 0.2s ease, color 0.2s ease',
            maxHeight: 300,
            overflowY: 'auto',
          }}>
            <Typography variant="h6" className="headline">Accounts</Typography>
            <Typography className="message">List of accounts</Typography>
            <List>
              {accounts.map((account) => (
                <ListItem key={account.id}>
                  <ListItemText
                    primary={<span style={{ color: 'var(--color-text)' }}>{account.name}</span>}
                    secondary={<span style={{ color: 'var(--color-text-secondary)' }}>{`Balance: ${account.currentBalance}`}</span>}
                  />
                </ListItem>
              ))}
            </List>
          </Paper>
        </Grid>

        {/* Transactions with Transaction Lines*/}
        <Grid item xs={12}>
        <Paper
            sx={{
              p: 2,
              backgroundColor: 'var(--color-surface)',
              color: 'var(--color-text)',
              border: '1px solid var(--color-border)',
              transition: 'background-color 0.2s ease, color 0.2s ease',
            }}
          >
            <Typography variant="h6" className="headline">
              Transactions
            </Typography>
            <TableContainer sx={{ overflowX: 'auto' }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: 'var(--color-text)' }}></TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>Transaction ID</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>Description</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>Amount</TableCell>
                    <TableCell sx={{ color: 'var(--color-text)' }}>Date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map((transaction) => (
                    <React.Fragment key={transaction.id}>
                      <TableRow>
                        <TableCell>
                          <IconButton
                            size="small"
                            onClick={() => handleRowToggle(transaction.id)}
                          >
                            {openRow[transaction.id] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                          </IconButton>
                        </TableCell>
                        <TableCell sx={{ color: 'var(--color-text-secondary)' }}>{transaction.id}</TableCell>
                        <TableCell sx={{ color: 'var(--color-text-secondary)' }}>{transaction.description}</TableCell>
                        <TableCell sx={{ color: 'var(--color-text-secondary)' }}>
                          {transaction.totalAmount
                            ? (Number(transaction.totalAmount) || 0).toFixed(2)
                            : '0.00'}
                        </TableCell>
                        <TableCell sx={{ color: 'var(--color-text-secondary)' }}>{transaction.transactionDate}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Collapse in={openRow[transaction.id]} timeout="auto" unmountOnExit>
                            {renderTransactionLines(transaction.id)}
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
