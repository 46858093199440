import React from 'react';
import { AppUI } from './AppUI';
import '../assets/styles/themes.css';
import '../assets/styles/global.css';

function App() {

  return (
      <AppUI/>
  );
}

export default App;
