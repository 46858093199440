import React, { useState, useEffect } from "react";
import { logoutUser, refreshAccessToken, loginUser, fetchUserSettings} from "../utils/api";
import { ThemeContext } from '../contexts/ThemeContext';

const AuthContext = React.createContext();

const AuthProvider = ({ children }) => {
  const { setTheme } = React.useContext(ThemeContext);
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Set initial state to false
  const [user, setUser] = useState(null); // store the user data
  const [accessToken, setAccessToken] = useState(null); // store the access token
  const [loading, setLoading] = useState(true); // set loading state to true

  useEffect(() => {
    // console.log('AuthContext effect triggered');
    const checkAuthentification = async () => {
      setLoading(true);

      try { // Check if the user is authenticated
        // check if the refresh token is in the cookie
        const response = await refreshAccessToken();

        if (response?.access_token && response?.user?.role) {
          // Update the access token and user data
          setAccessToken(response.access_token);
          localStorage.setItem('role', response.user.role); // Store role in local storage, TODO: encrypt the role and store it in local storage
          setUser(response.user);
          setIsAuthenticated(true);

          // Fetch user settings now that we have a user and token
          const settings = await fetchUserSettings(response.access_token);
          if (settings && settings.theme_preference) {
            // Apply the user's theme from settings
            setTheme(settings.theme_preference);
          }

        } else { // if the refresh token is not in the cookie, we will logout the user
          handleLogout();
          return;
        }

      } catch (error) { // if there is an error, we will logout the user
        handleLogout();
      } finally { // Allowing the app to render
        setLoading(false);
      }

    };

    checkAuthentification();
  }, [setTheme]);

  const handleLogin = async (username, password) => {
    try {
      const data = await loginUser(username, password); // Use the loginUser function from api.js
      setAccessToken(data.access_token);                // Store token in memory
      localStorage.setItem('role', data.user.role);     // Store role in local storage
      setUser(data.user);                               // Set user in state
      setIsAuthenticated(true);                         // Update authentication state

      const settings = await fetchUserSettings(data.access_token);
      console.log('Fetched settings:', settings);
      if (settings?.themePreference) {
        setTheme(settings.themePreference);
      }

    } catch (error) {
      // console.error('Login failed', error);                    // Display error message on login failure
      throw new Error('Login failed, Please check your credentials');
    }

  };

  const handleLogout = async () => {
    try {
      // check if the refresh token is in the cookie
      const refreshToken = document.cookie
      .split('; ')
      .find((row) => row.startsWith('refresh_token='))
      ?.split('=')[1];

      if (refreshToken) { // if the refresh token is in the cookie, we will logout the user and delete the cookie annd the refresh token from the database
        await logoutUser(refreshToken);
      }
      // Clear the local state and storage, regardless of backend success
      document.cookie = 'refresh_token=; Max-Age=0; Secure; HttpOnly;'; // delete the cookie
      localStorage.removeItem('role'); // delete the role from local storage TODO: encrypt the role and store it in local storage
      setAccessToken(null);
      setUser(null);
      setIsAuthenticated(false);

    } catch (error) {
      console.error('Logout failed:', error);
    }

  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setUser,
        accessToken,
        loading,
        handleLogin,
        handleLogout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );

};

export { AuthContext, AuthProvider };
