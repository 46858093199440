import React from 'react';
import {Routes, Route, Navigate, useLocation} from 'react-router-dom';
import { Home } from '../pages/main/Home';
import { Projects } from '../pages/main/Projects';
import VantaTrackerLogin from '../pages/vantaTracker/Login';
import AddUsers from '../pages/vantaTracker/AddUsers';
import Dashboard from '../pages/vantaTracker/Dashboard';
import MainLayout from '../layouts/MainLayout';
import VantaTrackerLayout from '../layouts/VantaTrackerLayout';
import PrivateRoute from '../utils/PrivateRoute';
import { AuthContext } from '../contexts/AuthContext';
import { ROLES } from '../constants/roleConstants';

function AppUI() {
  const {
    // isAuthenticated,
  } = React.useContext(AuthContext);

  return (
    <React.Fragment>
      <Routes>
        {/* Main Website Routes */}
        <Route
          path="/"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          }
        />
        <Route
          path="/home"
          element={
            <MainLayout>
              <Home />
            </MainLayout>
          }
        />
        <Route
          path="/projects"
          element={
            <MainLayout>
              <Projects />
            </MainLayout>
          }
        />

        {/* Vanta Tracker Login Route */}
        <Route
          path="/vanta-tracker/login"
          element={
            <MainLayout >
              <VantaTrackerLogin/>
            </MainLayout>
          }
        />
        {/* Vanta Tracker Protected Routes */}
        <Route
          path="/vanta-tracker/dashboard"
          element={
            <PrivateRoute allowedRoles={[ROLES.ADMIN, ROLES.SUPRAUSER, ROLES.USER]}>
              <VantaTrackerLayout >
                <Dashboard />
              </VantaTrackerLayout>
            </PrivateRoute>
          }
        />
        {/* Add Users Route for Admins */}
          <Route
            path="/vanta-tracker/add-users"
            element={
              <PrivateRoute allowedRoles={[ROLES.ADMIN]}>
                <VantaTrackerLayout>
                  <AddUsers />
                </VantaTrackerLayout>
              </PrivateRoute>
              }
          />
      </Routes>
    </React.Fragment>
  );
}


export { AppUI };
