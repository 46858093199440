const API_URL = `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_NAME}/api/${process.env.REACT_APP_API_VERSION}` || '';
// const API_URL = `${process.env.REACT_APP_API_URL}/api/v1` || '';


// Login user
export const loginUser = async (username, password) => {
  try {
    const response = await fetch(`${API_URL}/auth/login`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: username, password }),
      credentials: 'include', // Include cookies with requests
    });

    // console.log("Login Response Data:", data);
    if (!response.ok) {
      throw new Error('Login failed');
    }

    // We need to parse the response to get the data
    const data = await response.json();
    return data; // Correctly return parsed data
  } catch (error) {
    console.error('Error in loginUser function:', error);
    throw error;
  }
};

//Get user settings (theme selection)
export const fetchUserSettings = async (token) => {
  const response = await fetch(`${API_URL}/user/settings`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  });
  if (!response.ok) {
    throw new Error('Failed to fetch user settings');
  }
  return await response.json();
};

// Update userSettings (theme selection)
export const updateUserSettings = async (token, settingsData) => {
  const response = await fetch(`${API_URL}/user/settings`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(settingsData),
  });

  if (!response.ok) {
    throw new Error('Failed to update user settings');
  }

  return await response.json(); // or return nothing if no body is expected
};

// logout user
export const logoutUser = async (token) => {
  const response = await fetch(`${API_URL}/auth/logout`, {
      method: 'POST',
      headers: {
          Authorization: `Bearer ${token}`,
      },
  });

  if (!response.ok) {
      throw new Error('Logout failed');
  }

  return await response.json();
};
// Validate token
export const validateToken = async (token) => {
  try {
    const response = await fetch(`${API_URL}/auth/validate`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      throw new Error('Token validation failed');
    }
    return true; // Token is valid
  } catch (error) {
    console.error('Error validating token:', error);
    return false; // Token is not valid
  }
};

// Refresh access token
export const refreshAccessToken = async () => {
  try {
    const response = await fetch(`${API_URL}/auth/refresh-token`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        // body: JSON.stringify({ refreshToken }),
        credentials: 'include', // Include cookies with request
    });

    if (!response.ok) {
        throw new Error('Refresh token failed');
    }

    const data = await response.json();

    return data; // Return the new access
  } catch (error) {
      console.error('Error refreshing token:', error);
      throw error;
  }
};

//Fetch user data if authenticathed
export const fetchUsers = async (token) => {
  const response = await fetch(`${API_URL}/users`,{
    headers:{
      Authorization: `Bearer ${token}`
    },
  });

  if (!response.ok){
    throw new Error('Failed to fetch users');
  }

  return await response.json();
};


// Add a new user
export const addUser = async (newUser, token) => {
  const response = await fetch(`${API_URL}/users`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(newUser),
  });

  if (!response.ok) {
    const errorData = await response.json();
    return Promise.reject(errorData.message);
  }

  return await response.json();
};

// Fetch IFRS classifications list
export const fetchIFRSClassifications = async (token) => {
  const response = await fetch(`${API_URL}/ifrs-classifications`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch IFRS classifications');
  }
  console.log("IFRS Response:", response);
  return await response.json();
};

// Fetch currencies list
export const fetchCurrencies = async (token) => {
  const response = await fetch(`${API_URL}/currencies`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch currencies');
  }

  return await response.json();
}

// fetch accounts list
export const fetchAccounts = async (token) => {
  const response = await fetch(`${API_URL}/accounts`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch accounts');
  }

  return await response.json();
};

//fetch transactions list
export const fetchTransactions = async (token) => {
  const response = await fetch(`${API_URL}/transactions/with-amount`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch transactions');
  }

  return await response.json();
}

//fetch transaction lines list
export const fetchTransactionLines = async (token) => {
  const response = await fetch(`${API_URL}/transaction-lines`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch transaction lines');
  }

  return await response.json();
};

