import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import '../styles/Navbar.css'
import { AuthContext } from "../contexts/AuthContext";

function Navbar() {
  const { isAuthenticated, handleLogout } = React.useContext(AuthContext);
  const [menuOpen, setMenuOpen] = useState(false);


  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  }

  return (
    <header className="navigation">
      <nav className="padding-global padding-section-nav">
        <div className="container-nav">
          <a href="/" className="main_link">
            <p>JCZG - Juan Camilo Zabala Garcia</p>
          </a>
          <div className="menu_btn" onClick={toggleMenu}>
            <p className="caption text-color-alternate text-weight-medium">
              {menuOpen ? "[close]" : "[menu]"}
            </p>

          </div>

        </div>

        {menuOpen && (
          <div
            className={`menu_wrap`}
          >
            <div className="menu_base">

            </div>
            <div
              className={`menu_contain ${menuOpen ? 'grow-in' : 'shrink-out'}`}
              onMouseLeave={() => setMenuOpen(false)}
            >
              <Link to="/" className="menu_link option-fade-in" onClick={toggleMenu}>[home]</Link>
              <Link to="/about" className="menu_link option-fade-in" onClick={toggleMenu}>[about me]</Link>
              <Link to="/projects" className="menu_link option-fade-in" onClick={toggleMenu}>[projects]</Link>
              <Link to="/services" className="menu_link option-fade-in" onClick={toggleMenu}>[services]</Link>
              {isAuthenticated && (
                <Link to="/vanta-tracker/login" className="menu_link option-fade-in" onClick={ () => {
                  toggleMenu()  //close the menu
                  handleLogout() //handle logout
                }}>
                  [log out]
                </Link>
              )}
            </div>

          </div>
        )}
      </nav>
    </header>
  );
}

export default Navbar;



// const navigate = useNavigate();
// const role = localStorage.getItem('role');

// const handleSignOut = () => {
//   handleLogout();
//   navigate('/')
// }
          // <div>
          //   <ul>
          //     <li>
          //       <Link to="/">Home</Link>
          //     </li>
          //     <li>
          //       <Link to="/home">Home Provisional</Link>
          //     </li>
          //     {!isAuthenticated && (
          //       <li>
          //         <Link to="/login">Login</Link>
          //       </li>
          //     )}
          //     {isAuthenticated && (
          //       <>
          //         {role === 'adm1n' && (
          //           <li>
          //             <Link to="/add-users">Add Users</Link>
          //           </li>
          //         )}
          //         <li>
          //           <button onClick={handleSignOut}>
          //             Sign Out
          //           </button>
          //         </li>
          //       </>
          //     )}
          //   </ul>

          // </div>
