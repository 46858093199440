import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../styles/Login.css"
import { AuthContext } from "../../contexts/AuthContext";
// import LoginForm from "../components/LoginForm";

function VantaTrackerLogin() {
  const { handleLogin } = React.useContext(AuthContext);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await handleLogin(username, password);              // Use the handleLogin function from AuthContext
      navigate('/vanta-tracker/dashboard');               // Navigate to the dashboard
    } catch (error) {
      console.error("Login error:", error);
      setErrorMessage('Login failed, Please check your credentials');                   // Display error message on login failure
    }
  };


  return (

    <>
      <div className="page-wrapper">
        <div className="login_page">
          <div className="section_login">
            <div className="section_form padding-global">
              <form className="form-login" onSubmit={handleSubmit}>
                <label htmlFor="username" className="label-login">Username:</label>
                <input type="text" className="input-login" value={username} onChange={(e) => setUsername(e.target.value)} />
                <label htmlFor="password" className="label-login">Password:</label>
                <input type="password" className="input-login" value={password} onChange={(e) => setPassword(e.target.value)} />
                <button type="submit" className="button-login text-weight-medium">[login]</button>
              </form>
              {errorMessage && <p className="error-message">{errorMessage}</p>} {/* Display error message */}
            </div>

          </div>
        </div>
      </div>

    </>

  );
}

export default VantaTrackerLogin;
