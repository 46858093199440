import React, { createContext, useContext, useState } from 'react';
import { fetchUsers, fetchIFRSClassifications, fetchAccounts,
  fetchCurrencies, fetchTransactions, fetchTransactionLines } from '../utils/api';
import { AuthContext } from './AuthContext';

const DataContext = createContext();

const DataProvider = ({ children }) => {
  const { accessToken } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [ifrsClassifications, setIFRSClassifications] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [transactions, setTransactions] = useState([]);
  const [transactionLines, setTransactionLines] = useState([]);

  const fetchData = async () => {
    try {
      const [usersData, ifrsData, accountsData, currenciesData,transactionsData,transactionLinesData  ] = await Promise.all([
        fetchUsers(accessToken),
        fetchIFRSClassifications(accessToken),
        fetchAccounts(accessToken),
        fetchCurrencies(accessToken),
        fetchTransactions(accessToken),
        fetchTransactionLines(accessToken),
      ]);

      setUsers(usersData);
      setIFRSClassifications(ifrsData);
      setAccounts(accountsData);
      setCurrencies(currenciesData);
      setTransactions(transactionsData);
      setTransactionLines(transactionLinesData);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  return (
    <DataContext.Provider value={{
      users,
      ifrsClassifications,
      accounts,
      currencies,
      transactions,
      transactionLines,
      fetchData
    }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
